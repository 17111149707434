export class GetUserProfileOutputApiModel {
    admin_companies: Array<number>;
    avatar: string;
    display_name: string;
    email: string;
    first_name: string;
    last_name: string;
    id: number;
    slug: string;
    story_statistics: { given: number; people_added: number; people_named: number; received: number; total: number }

    constructor(init?: Partial<GetUserProfileOutputApiModel>) {
        Object.assign(this, init);
    }
}