import { CreateCompanyInputApiModel } from './../models/api-models/input/create-company-input.api-model';
import { map } from 'rxjs/operators';
import { Company } from './../models/api-models/output/company';
import { HttpClient } from '@angular/common/http';
import { HttpService } from '@framework/mvc/services/http.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SearchOutputApiModel } from '../models/api-models/output/search-output.api-model';
import {CompanyValidateFieldOutputApiModel} from '@app/modules/company/models/api-models/output/company-validate-field-output.api-model';

@Injectable()
export class CompanyService extends HttpService {
    constructor(http: HttpClient) {
        super(http, 'companies');
    }

    public getCompanies(params: object): Observable<any> {
        const url  = super.revertParamsObjectToUrl(`admin/`, params);
        return super.get(url).pipe(
            map((data: SearchOutputApiModel<Company>) => {
                if (data.result.length > 0) {
                    data.result = data.result.map((item: any) => new Company(item));
                }
                return data;
            })
        );
    }

    public validateValueByKey(params: object): Observable<any> {
        const url = 'admin/company-field-validate/';
        return super.post(url, params).pipe(
            map((data: any) => new CompanyValidateFieldOutputApiModel(data))
        );
    }

    public createNewCompany(data: CreateCompanyInputApiModel): Observable<any> {
        const url: string = 'admin/';
        return super.post(url, data);
    }

    public getCompanyDetail(id: number): Observable<any> {
        const url: string = `${id}/admin/`;
        return super.get(url);
    }
    
    public updateCompanyDetail(id: number, data: any): Observable<any> {
        const url: string = `${id}/admin/`;
        return super.patch(url, data);
    }

    public getCompanyByName(name: string): Observable<any> {
        const url  = super.revertParamsObjectToUrl(`admin/search/`, { company_name: name });
        return super.get(url);
    }
}
