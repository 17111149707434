import { GetUserProfileOutputApiModel } from './../../../modules/shared/models/api-models/output/get-user-profile-output.api-model';

import { MemoizedSelector, createSelector } from '@ngrx/store';
import { AuthState } from '../state/auth.state';
import { selectAuthFeature } from './auth.feature';
import { AuthorizedUserModel } from '@framework/models/users/authorized-user.model';

const selectAuthState: MemoizedSelector<object, AuthState> = createSelector(selectAuthFeature, (state: AuthState) => state);


// -------------[Mapping Functions]------------- 
const getIsAuthenticated = (state: AuthState) => {
    return state.isAuthenticated;
}
const getUserContext = (state: AuthState) => {
    return state.userContext;
}

const getUserProfile = (state: AuthState) => {
    return state.userProfile;
}


// -------------[Public Selectors]-------------
export const selectIsAuthenticated: MemoizedSelector<object, boolean> = createSelector(selectAuthState, getIsAuthenticated);
export const selectUserContext: MemoizedSelector<object, AuthorizedUserModel> = createSelector(selectAuthState, getUserContext);
export const selectUserProfile: MemoizedSelector<object, GetUserProfileOutputApiModel> = createSelector(selectAuthState, getUserProfile);