import { UploaderService } from './services/uploader.service';
import { HttpInterceptorService, ErrorInterceptor } from './services/http-interceptor.service';
import { parse } from 'url';
import { SharedModule } from '@app/modules/shared/shared.module';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import * as pages from './pages';
import { RootStateModule } from '@app/state/rootstate.module';
import { AuthGuard } from '../auth/guards/auth.guard';
import { AuthHttpService } from '../auth/services/auth.http-service';
import { AppSettingHttpService } from './services/app-setting.http-service';
import { ComponentModule } from './components/component.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CompanyService } from '../company/services/company.service';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { CompanyEffect } from '../company/effects/company.effect';

const routes: Routes = [
    {
        path: '',
        component: pages.LayoutPage,
        children: [
            {
                path: 'auth',
                component: pages.PublicPage,
                loadChildren: '../auth/auth.module#AuthModule'
            },
            {
                path: 'policy',
                component: pages.PublicPage,
                loadChildren: '../policy/policy.module#PolicyModule'
            },
            {
                path: '',
                redirectTo: 'company',
                pathMatch: 'full'
            },
            {
                path: 'company',
                component: pages.MainPage,
                canActivate: [AuthGuard],
                children: [
                    {
                        path: '',
                        loadChildren: './../company/company.module#CompanyModule'
                    }
                ]
            },
            {
                path: 'user',
                component: pages.MainPage,
                canActivate: [AuthGuard],
                children: [
                    {
                        path: '',
                        loadChildren: './../user/user.module#UserModule'
                    }
                ]
            }
            // {
            //     path: '**',
            //     component: pages.LayoutPage,
            // }
        ]
    }
];

@NgModule({
    declarations: [
        pages.LayoutPage,
        pages.MainPage,
        pages.PublicPage
    ],
    imports: [
        CommonModule,
        RouterModule.forRoot(routes),
        ComponentModule,
        RootStateModule.forRoot(),
        EffectsModule.forRoot([CompanyEffect]),
        SharedModule.forRoot(),
    ],
    exports: [
        RouterModule,
        SharedModule
    ]
})
export class CoreRoutingModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: CoreRoutingModule,
            providers: [
                AuthGuard, 
                AuthHttpService,
                CompanyService, 
                AppSettingHttpService,
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: HttpInterceptorService,
                    multi: true
                },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: ErrorInterceptor,
                    multi: true
                },
                UploaderService
            ]
        }
    }
}
