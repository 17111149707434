import { ModuleWithProviders, NgModule } from '@angular/core';
import { MvcModule } from './mvc/mvc.module';
import { CognitoModule } from './cloud/cognito/cognito.module';
import * as uti from './utilities';



@NgModule({
    declarations: [],
    imports: [
        CognitoModule.forRoot(),    
        MvcModule.forRoot(),
    ],
    exports: [
        MvcModule
    ]    
})
export class FrameworkModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: FrameworkModule,
            providers: [
                uti.storage.StorageProvider,
                uti.EncodeHelper,
                uti.FileHelper,
                uti.FormHelper,
                uti.GuidHelper,
                uti.JqueryHelper,
                uti.LodashHelper,
                uti.MimeTypesHelper,
                uti.MomentHelper,
                uti.NumberHelper,
                uti.StringHelper,
                uti.TokenHelper,
                uti.UtilityHelper
            ]
        }
    }
}