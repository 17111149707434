
import { rootReducer } from './reducers/root.reducer';
import { StoreRouterConnectingModule, RouterStateSerializer, RouterState } from '@ngrx/router-store';
import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { RouterUrlSerializer } from './state/router.state';
import * as coreEffects from './effects';
import { coreReducer } from './reducers/core.reducer';
import { appSettingReducer } from './reducers/app-setting.reducer';
import { AuthStateModule } from './../auth/auth-state.module';



@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot(rootReducer),
    EffectsModule.forRoot([]),
    StoreModule.forFeature('core', coreReducer),
    StoreModule.forFeature('appSetting', appSettingReducer),
    EffectsModule.forFeature([
      coreEffects.AppSettingEffects
    ]),
    AuthStateModule.forRoot(),

    StoreRouterConnectingModule.forRoot()
  ],
  providers: [
    { provide: RouterStateSerializer, useClass: RouterUrlSerializer }
  ]

})

export class CoreStateModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreStateModule,
      providers: []
    }
  }
}