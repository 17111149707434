import { loginSuccess } from '@app/state/auth/actions/auth.actions';
import { Actions, ofType } from '@ngrx/effects';
import { Subscription } from 'rxjs';
import { LoadingService } from './../../services/loading.service';
import { Component, SimpleChanges, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { PublicPageBase } from '@framework/base/public-page/public-page.base';
import { map } from 'rxjs/operators';
import { UserState } from '@app/modules/user/reducers/user.reducer';
import { Store } from '@ngrx/store';
import { authActions } from '@app/state/auth/actions';

@Component({
    selector: 'layout-page',
    templateUrl: './layout.page.html',
    styleUrls: ['./layout.page.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LayoutPage extends PublicPageBase {
    loading: boolean;
    loadingSubscription: Subscription;
    private loginSuccessSubscription: Subscription;
    constructor(
        private loadingService: LoadingService, private changeDetector: ChangeDetectorRef,
        private dispatcher: Actions, private store: Store<UserState>
        ) {
        super();
    }

    subscribe(): void {
        this.loadingSubscription = this.loadingService.loading.subscribe((loading: boolean) => {
            this.loading = loading;
            this.changeDetector.detectChanges();
        });
        this.loginSuccessSubscription = this.dispatcher.pipe(
            ofType(loginSuccess),
            map(action => action.output)
        ).subscribe((data: any) => {
            this.store.dispatch(authActions.getProfile());
        })
    }

    protected onInit(): void {
        this.subscribe();
    }
    protected onDestroy(): void {
        if (this.loadingSubscription) {
            this.loadingSubscription.unsubscribe();
        }
        if (this.loginSuccessSubscription) {
            this.loginSuccessSubscription.unsubscribe();
        }
    }
    protected onChanges(changes: SimpleChanges): void {
    }
    protected onDoCheck(): void {
    }
    protected onAfterContentInit(): void {
    }
    protected onAfterContentChecked(): void {
    }
    protected onAfterViewInit(): void {
    }
    protected onAfterViewChecked(): void {
    }
}
