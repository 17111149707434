import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { authReducer } from './reducers';
import { EffectsModule } from '@ngrx/effects';
import * as authEffects from './effects';




@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature('auth', authReducer),        
        EffectsModule.forFeature([
            authEffects.AuthEffects
        ]),
    ],
})

export class AuthStateModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: AuthStateModule,
            providers: []
        }
    }
}