import { createReducer, Action, on } from '@ngrx/store';
import { AuthState, initialAuthState } from '../state/auth.state';
import { authActions } from '../actions';

const reducer = createReducer(
    initialAuthState,
    on(authActions.loginSuccess, ((state: AuthState, { output }) => {
        return {
            ...state,
            isAuthenticated: true,
            userContext: output.userContext     
        };
    })),
    on(authActions.getProfileSuccess, ((state: AuthState, { input }) => {
        return {
            ...state,
            userProfile: input
        };
    })),
    on(authActions.logoutSuccess, ((state: AuthState, { output }) => { 
        return {
            ...state,
            userProfile: null
        }
    })),
);

export function authReducer(state: AuthState | undefined, action: Action) {
    return reducer(state, action);
}