import { LoadingService } from '@app/modules/core/services/loading.service';
import { SearchOutputApiModel } from './../models/api-models/output/search-output.api-model';
import { Company } from '../models/api-models/output/company';
import { switchMap, map, catchError, concatMap } from 'rxjs/operators';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import * as companyActions from './../actions/company.action';
import { CompanyService } from '../services/company.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { CompanyValidateFieldOutputApiModel } from '@app/modules/company/models/api-models/output/company-validate-field-output.api-model';

@Injectable()
export class CompanyEffect {
    constructor(private actions: Actions, private companyService: CompanyService, private loadingService: LoadingService) {
    }
    searchCompanies$ = createEffect(() => this.actions.pipe(
        ofType(companyActions.SearchListCompanies),
        switchMap(action => {
            return this.companyService.getCompanies(action.payload).pipe(
                map((data: SearchOutputApiModel<Company>) => {
                    this.loadingService.changeLoading(false);
                    return companyActions.SearchListCompaniesSuccess(
                        {
                            payload:
                                { data: data.result, count: data.count }
                        }
                    )
                }
                ),
                catchError((error: HttpErrorResponse) => {
                    this.loadingService.changeLoading(false);
                    return of(companyActions.SearchListCompaniesFail({ payload: action.payload, error: error.message }));
                }),
                
            );
        })
    ));

    validateFieldExistCompany$ = createEffect((): any => {
        return this.actions.pipe(
            ofType(companyActions.ValidateFieldExistCompany),
            concatMap(action => {
                return this.companyService.validateValueByKey(action.payload).pipe(
                    map((data: CompanyValidateFieldOutputApiModel) => {
                        return companyActions.ValidateFieldExistCompanySuccess({ payload: data });
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return of(companyActions.ValidateFieldExistCompanyFail({ payload: action.payload, error: error.message }));
                    })
                );
            })
        );
    });
    
    createNewCompany$ = createEffect((): any => {
        return this.actions.pipe(
            ofType(companyActions.CreateNewCompany),
            switchMap(action => {
                return this.companyService.createNewCompany(action.payload).pipe(
                    map((data: any) => {
                        return companyActions.CreateNewCompanySuccess({ payload: data });
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return of(companyActions.CreateNewCompanyFail({ payload: action.payload, error: error.error.message }))
                    })
                )
            })
        )
    });

    getCompanyDetail$ = createEffect((): any => {
        return this.actions.pipe(
            ofType(companyActions.GetCompanyDetail),
            switchMap(action => {
                return this.companyService.getCompanyDetail(action.payload.id).pipe(
                    map((data: any) => {
                        return companyActions.GetCompanyDetailSuccess({ payload: data })
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return of(companyActions.GetCompanyDetailFailure({ payload: action.payload, error: error.message }))
                    })
                )
            })
        )
    });

    updateCompanyDetail$ = createEffect((): any => {
        return this.actions.pipe(
            ofType(companyActions.UpdateCompanyInfo),
            switchMap(action => {
                return this.companyService.updateCompanyDetail(action.payload.id, action.payload.data).pipe(
                    map((data: any) => {
                        return companyActions.UpdateCompanyInfoSuccess({ payload: { data } });
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return of(companyActions.UpdateCompanyInfoFail({ payload: action.payload, error: error.message }));
                    })
                )
            })
        )
    });

    getCompanyByName$ = createEffect((): any => {
        return this.actions.pipe(
            ofType(companyActions.GetCompanyByName),
            concatMap(action => {
                return this.companyService.getCompanyByName(action.payload.name).pipe(
                    map((item: any) => {
                        return companyActions.GetCompanyByNameSuccess({ payload: item })
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return of(companyActions.GetCompanyByNameFail({ payload: action.payload, error: error.error.message }))
                    })
                )
            })
        )
    })
}
