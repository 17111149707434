import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoadingService {
    private loadingSource: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public loading: Observable<boolean> = this.loadingSource.asObservable();

    changeLoading(loading: boolean): void {
        this.loadingSource.next(loading);
    }
}