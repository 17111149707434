import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class TokenHelper {
    constructor(
    ) { }

    public getToken(): string {
        const token: string = localStorage.getItem('idToken');
        return token;
    }

    public isTokenExpired(token: string) {
        const tokenDecoded = this.tokenDecode(token);

        if (!tokenDecoded) return true;

        if (tokenDecoded.exp < new Date().getTime() / 1000) {
            return true;
        }

        return false;
    }

    public tokenDecode(token: string) {
        var base64Url = token.split('.')[1];
        if (!base64Url) return null;
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        return JSON.parse(window.atob(base64));
    }
}
