import { CognitoUserAttribute } from 'amazon-cognito-identity-js';

export interface SignUpModel {
    username: string,
    password: string,
    attributes: CognitoUserAttribute[]
}

export class SignUpModel {
    constructor() {
        this.attributes = [];
    }
}