import { ModelBase } from '@framework/models/base/base.model';

export class ColumnModel extends ModelBase {    
    public name: string;
    public type: string;
    public displayName: string;
    public order: number;
    public visible: boolean;
    public sortable: boolean;
    public css: any;
    public defaultValue: string;
    public children: Array<any> = [];
    public canHover: boolean;
    public width: string;
    public onClick: (...agrs) => void;

    public getValueOfItem(item: any, text: string, keyOfValue: string, markStart: string = null, markEnd: string = null): string {
        return text +' '+ markStart + item[keyOfValue] + markEnd;
    }
    
    constructor(init?: Partial<ColumnModel>) {
        super(init);
        Object.assign(this, init);
    }
}