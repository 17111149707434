import Base64 from 'crypto-js/enc-base64';
import SHA256 from 'crypto-js/sha256';
import { parse } from 'url';

import * as oAuthStorage from '../models';
import { Auth0OAuthOpts } from '../models';
import { OAuthDialogService } from './oauth-dialog.service';
import { environment } from '@app/env/environment';

export class OAuth {
    private authEnv = environment.aws;

    constructor(private oAuthDialogService: OAuthDialogService, 
                private config: Auth0OAuthOpts, 
                private scopes: string[]) {

    }

    // Private functions
    private generateState(length: number) {
        let result = '';
        let i = length;
        const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        for (; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
        return result;
    }

    private generateChallenge(code: string) {
        return this.base64URL(SHA256(code));
    }

    private base64URL(string) {
        return string.toString(Base64).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
    }

    private generateRandom(size: number) {
        const CHARSET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
        const buffer = new Uint8Array(size);
        if (typeof window !== 'undefined' && !!(window.crypto)) {
            window.crypto.getRandomValues(buffer);
        } else {
            for (let i = 0; i < size; i += 1) {
                buffer[i] = (Math.random() * CHARSET.length) | 0;
            }
        }
        return this.bufferToString(buffer);
    }

    private bufferToString(buffer: Uint8Array) {
        const CHARSET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const state = [];
        for (let i = 0; i < buffer.byteLength; i += 1) {
            const index = buffer[i] % CHARSET.length;
            state.push(CHARSET[index]);
        }
        return state.join('');
    }

    private validateState(urlParams: any) {
        if (!urlParams) { return; }

        const savedState = oAuthStorage.getState();
        const { state: returnedState } = urlParams;

        if (savedState && savedState !== returnedState) {
            throw new Error('Invalid state in OAuth flow');
        }
    }

    private async handleCodeFlow(currentUrl: string) {
        /* Convert URL into an object with parameters as keys
        { redirect_uri: 'http://localhost:3000/', response_type: 'code', ...} */
        const { code } = (parse(currentUrl).query || '')
            .split('&')
            .map((pairings) => pairings.split('='))
            .reduce((accum, [k, v]) => ({ ...accum, [k]: v }), { code: undefined });

        if (!code) { return; }


        const oAuthTokenEndpoint = 'https://' + this.config.domain + '/oauth2/token';

        const client_id = this.config.clientID;
        const basicHash = `${client_id}:${this.authEnv.cognito.secretKey}`;

        const redirect_uri = this.config.redirectUri;

        const code_verifier = oAuthStorage.getPKCE();

        const oAuthTokenBody = {
            grant_type: 'authorization_code',
            code,
            client_id,
            redirect_uri,
            ...(code_verifier ? { code_verifier } : {})
        };


        const body = Object.entries(oAuthTokenBody)
            .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
            .join('&');

        const { access_token, refresh_token, id_token, error } = await (await fetch(oAuthTokenEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Basic ${btoa(basicHash)}`
            },
            body: typeof URLSearchParams !== 'undefined' ? new URLSearchParams(body) : body
        }) as any).json();

        if (error) {
            throw new Error(error);
        }

        return {
            accessToken: access_token,
            refreshToken: refresh_token,
            idToken: id_token
        };
    }
    // Public functions
    public async oauthSignIn(responseType = 'code', provider: string): Promise<{ accessToken: string, refreshToken: string, idToken: string }> {
        const state = this.generateState(32);
        oAuthStorage.setState(state);

        const pkce_key = this.generateRandom(128);
        oAuthStorage.setPKCE(pkce_key);

        const code_challenge = this.generateChallenge(pkce_key);
        const code_challenge_method = 'S256';

        const queryString = Object.entries({
            response_type: responseType,
            client_id: this.config.clientID,
            redirect_uri: this.config.redirectUri,
            identity_provider: provider,
            scopes: this.scopes,
            state,
            ...(responseType === 'code' ? { code_challenge } : {}),
            ...(responseType === 'code' ? { code_challenge_method } : {})
        }).map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`).join('&');

        const URL = `https://${this.config.domain}/oauth2/authorize?${queryString}`;
        const responseUrl = await this.oAuthDialogService.open(URL, this.config.redirectUri);

        return await this.handleAuthResponse(responseUrl);
    }

    public async handleAuthResponse(currentUrl?: string) {
        const urlParams = currentUrl ? {
            ...(parse(currentUrl).hash || '#').substr(1)
                .split('&')
                .map(entry => entry.split('='))
                .reduce((acc, [k, v]) => (acc[k] = v, acc), {}),
            ...(parse(currentUrl).query || '')
                .split('&')
                .map(entry => entry.split('='))
                .reduce((acc, [k, v]) => (acc[k] = v, acc), {})
        } as any : {};
        const { error, error_description } = urlParams;

        if (error) {
            throw new Error(error_description);
        }

        this.validateState(urlParams);

        return this.handleCodeFlow(currentUrl);
    }
}