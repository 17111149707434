import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FrameworkModule } from '@framework/framework.module';
import { ImageCloudinaryPipe } from './pipes/image-cloundinary.pipe';



@NgModule({
  declarations: [
      ImageCloudinaryPipe
  ],
  imports: [
    CommonModule,
    FrameworkModule.forRoot()
  ],
  exports: [
    FrameworkModule,
    ImageCloudinaryPipe
  ],
  providers: []
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [  ]
    }
  }
  static forChild(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [  ]
    }
  }
}
