import { Subscription } from 'rxjs';
import { GetUserProfileOutputApiModel } from './../../../shared/models/api-models/output/get-user-profile-output.api-model';
import { authActions } from '@app/state/auth/actions';
import { Store } from '@ngrx/store';
import { LocalStorageKeyConstant } from './../../../shared/constants/localstorage-key.constant';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { selectUserProfile } from '@app/state/auth/selectors';
import { RootState } from '@app/state/core/state/root.state';
import { loginSuccess } from '@app/state/auth/actions/auth.actions';

@Component({
    selector: 'layout-sidebar',
    templateUrl: './layout-sidebar.component.html',
    styleUrls: ['./layout-sidebar.component.scss']
})
export class LayoutSidebarComponent implements OnInit, OnDestroy {
    userInfo: GetUserProfileOutputApiModel;
    selectUserProfileSource: Subscription;

    constructor(private router: Router, private store: Store<RootState>) {   
    }

    ngOnInit() {
        this.store.select(selectUserProfile).subscribe((data: GetUserProfileOutputApiModel) => {
            if (!data) {
                this.getProfile();
                return;
            }

            this.userInfo = data;
        });
    }

    getProfile(): void {
        this.store.dispatch(authActions.getProfile());
    }
    
    logout(): void {
        localStorage.removeItem(LocalStorageKeyConstant.AUTH_KEY);
        this.router.navigateByUrl('/auth');
    }

    ngOnDestroy() {
        if (this.selectUserProfileSource) {
            this.selectUserProfileSource.unsubscribe();
        }
    }
}
