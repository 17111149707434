export class CompanyValidateFieldOutputApiModel {
  public valid: any;
  public column: string;
  public value: string | number;
  public title: string;

  constructor(init?: Partial<CompanyValidateFieldOutputApiModel>) {
    Object.assign(this, init);
  }
}
