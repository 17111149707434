import { createAction, props } from '@ngrx/store';
import * as inputApi from '@app/modules/shared/models/api-models/input';
import * as outputApi from '@app/modules/shared/models/api-models/output';


export const login = createAction(
    '[User] User Login',
    props<{input: inputApi.LoginInputApiModel}>()
);

export const loginSuccess = createAction(
    '[User] User Login Success',
    props<{output: outputApi.LoginOutputApiModel}>()
);

export const loginFail = createAction(
    '[User] User Login Fail',
    props<{input: inputApi.LoginInputApiModel, error: any}>()
);

export const logout = createAction(
    '[User] User Logout',
    props<{input: inputApi.LogoutInputApiModel}>()
);

export const logoutSuccess = createAction(
    '[User] User Logout Success',
    props<{output: outputApi.LogoutOutputApiModel}>()
);

export const logoutFail = createAction(
    '[User] User Logout Fail',
    props<{input: inputApi.LogoutInputApiModel, error: any}>()
);

export const getProfile = createAction(
    '[User] User Get Profile'
);

export const getProfileSuccess = createAction(
    '[User] User Get Profile Success',
    props<{input: outputApi.GetUserProfileOutputApiModel}>()
);

export const getProfileFail = createAction(
    '[User] User Get Profile Fail',
    props<{ error: string }>()
);