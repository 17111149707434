import { ComponentBase } from 'framework/base/components/component/component.base';
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'grid-pagination',
    templateUrl: './grid-pagination.component.html',
    styleUrls: ['./grid-pagination.component.scss']
})
export class GridPagination extends ComponentBase {
    @Input() total: number = 1;
    @Input('countItemPerPage') countItemPerPage: number = 0;
    @Output('page') page: EventEmitter<any> = new EventEmitter<any>(null);
    @Input('currentPage') currentPage: number = 1;
    listPaginations: Array<number> = [];
    paginations: Array<number | string> = [];
    currentIndex: number = 0;
    
    constructor() {
        super()
    }
    protected onInit(): void {
    }    
    protected onDestroy(): void {
    }
    protected onChanges(changes: import("@angular/core").SimpleChanges): void {
        this.caculatePagination();
    }

    private caculatePagination() {
        const lengthOfData: number = this.total ? Math.ceil(this.total / this.countItemPerPage) : 0;
        this.listPaginations = Array(lengthOfData).fill(null).map((x: any, i: number) => i);
        this.remapListPagination(this.listPaginations);

    }
    protected onDoCheck(): void {
    }
    protected onAfterContentInit(): void {
    }
    protected onAfterContentChecked(): void {
    }
    protected onAfterViewInit(): void {
    }
    protected onAfterViewChecked(): void {
    }

    handlePaging(isNumberPaging: boolean, typeOrNumberPaging: string | number): void {
        if (isNumberPaging) {
            this.currentPage = +typeOrNumberPaging;
        } else {
            if(typeOrNumberPaging === 'next') {
                if(this.currentPage === this.listPaginations.length) {
                    this.currentPage = this.listPaginations.length;
                } else {
                    this.currentPage += 1;
                }
            } else {
                if(this.currentPage === 1) {
                    this.currentPage = 1;
                } else {
                    this.currentPage -= 1;
                }
            }
        }
        this.remapListPagination(this.listPaginations, true);
    }

    private remapListPagination(listPaginations: Array<number>, emit: boolean = false) {
        const maxPage: number = listPaginations.length - 1;
        if(this.currentPage <= 7) {
            if(listPaginations.length > 7) {
                this.paginations = [...listPaginations.slice(0, 7), '...', maxPage];
            } else {
                this.paginations = listPaginations;
            }
        } else if(this.currentPage > (maxPage - 4) && this.currentPage <= maxPage + 1) {
            const length = listPaginations.slice(maxPage - 4, maxPage + 1);
            this.paginations = [0, '...', ...length];
        } else {
            this.paginations = [0, '...', this.currentPage - 2, this.currentPage -1, this.currentPage, '...', maxPage];
        }
        this.currentIndex = this.currentPage - 1;
        if (emit) {
            this.page.emit(this.currentPage);
        }
    }

    public handleThreedot(index: number): void {
        this.handleThreedotPagination(this.listPaginations, index);
    }

    private handleThreedotPagination(listPaginations: Array<number>, index: number): void {
        const maxPage: number = listPaginations.length;
        if(this.currentPage <= 7) {
           this.currentPage = 8;
        } else if(this.currentPage > (maxPage - 4) && this.currentPage <= maxPage) {
        } else {
            if(index < this.currentPage) {
                this.currentPage = this.currentPage - 2;
            } else {
                this.currentPage = this.currentPage + 2;
            }
        }
        this.remapListPagination(this.listPaginations);
    }
}