export interface AppSettingState {
    appSetting: any,
    isAppSettingLoaded: boolean,
    isAppSettingLoading: boolean
}

export const initialAppSettingState: AppSettingState = {
    appSetting: {},
    isAppSettingLoaded: false,
    isAppSettingLoading: false
}