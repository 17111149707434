// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import * as baseEnvironment from './base';

export const environment = {
    production: false,
    apiUrl: '/api/v3',
    assets: 'assets/images',
    auth: {
        login: '/auth',
        redirectUrl: '/'
    },
    endpointUrl: '/api/v3',
    apiDomain: 'http://localhost:5000',
    aws: {
        accessId: 'AKIAIJJZJHU3PKGKJ2PA',
        s3: {
            bucketName: 'elasticbeanstalk-us-east-1-534096778644',
            path: 'goodseeker',
            region: 'us-east-1',
            uploadEnpoint: 'https://elasticbeanstalk-us-east-1-534096778644.s3-accelerate.amazonaws.com',
        },
        cognito: {
            userPoolId: 'us-east-1_JinNFLnNh',
            clientId: '5ro5onded5eeug1e0sjo3grk4f',
            secretKey: '20jg0pp5cca5e5hqpv73186j7shm08jp95g4ha4hete1ee2m93d'
        },
        oAuth: {
            domain: 'goodseeker-ci.auth.us-east-1.amazoncognito.com',
            redirectUrl: 'http://localhost:4201/authorize'
        }
    },
    cloudinary: {
        cdnVideoRootUrl: 'https://media.goodseeker.com/video',
        cdnImageRootUrl: 'https://media.goodseeker.com/image',
        convertToNewCdnUrl: baseEnvironment.convertToNewCdnUrl,
        s3RootUrl: 'https://elasticbeanstalk-us-east-1-534096778644.s3.amazonaws.com',
        keyPrefix: 's3'
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
