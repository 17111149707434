import { Injectable } from '@angular/core';

@Injectable()
export class StringHelper {
    private emailRegex = new RegExp('^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$');
    constructor() { }

    public emailParser(inputString: string): { fullName: string, email: string } {
        const matchArr = inputString.match(/([^<]+)<([^>]*)>/i);
        if (matchArr) {
            return {
                fullName: matchArr[1],
                email: matchArr[2]
            };
        }

        return {
            fullName: inputString,
            email: ''
        };
    }

    public isEmail(source: string) {
        return source && this.emailRegex.test(source);
    }

    public isOutlookFormat(source: string) {
        return source && source.indexOf('<') > -1 && source.indexOf('>') > -1;
    }

    public isOutlookEmailFormat(source: string) {
        return source && source.indexOf('<') > -1 && source.indexOf('>') > -1 && source.indexOf('@');
    }
}
