import { AuthHttpService } from '@app/modules/auth/services';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { tap } from 'rxjs/operators';
import { environment } from '@app/env/environment';


@Injectable()
export class AuthGuard implements CanActivate {
    private authEnv = environment.auth;
    
    constructor(private authService: AuthHttpService, private router: Router){
        
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if (this.authService.isAuthenticated()) return true;
        return this.authService.isAuthenticatedState().pipe(
            tap((isAuthenticated: boolean) => {
                if (isAuthenticated === false) this.router.navigate([this.authEnv.login]);
            })
        );
    }
}