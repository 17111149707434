import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from '@framework/mvc/services/http.service';
import * as inputApi from '@app/modules/shared/models/api-models/input';
import * as outputApi from '@app/modules/shared/models/api-models/output';


@Injectable()
export class AppSettingHttpService extends HttpService {
    constructor(protected httpClient: HttpClient) {
        super(httpClient, 'app');
    }

    public getAppSetting(input: inputApi.GetAppSettingInputApiModel) {
        return this.get<outputApi.GetAppSettingOutputApiModel>('settings/');
    }
}