import { GetUserProfileOutputApiModel } from './../../shared/models/api-models/output/get-user-profile-output.api-model';
import { LocalStorageKeyConstant } from './../../shared/constants/localstorage-key.constant';


import { Injectable } from '@angular/core';

import { AuthorizedUserModel } from '@framework/models/users/authorized-user.model';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { LoginModel } from '@framework/models/users/login.model';
import { CognitoAuthService } from '@framework/cloud/cognito/cognito-auth.service';
import { HttpService } from '@framework/mvc/services/http.service';
import { HttpClient } from '@angular/common/http';
import { SignInModel } from '@framework/cloud/cognito/models';
import * as moment from 'moment';


@Injectable()
export class AuthHttpService extends HttpService {

    private currentUser: AuthorizedUserModel = null;

    constructor(protected httpClient: HttpClient,
        private cognitoService: CognitoAuthService) {
        super(httpClient, '');
    }

    private async setCurrentUser() {
        // this.currentUser = await this.firebaseAuthService.getAuthorizedUser();
    }

    public async getCurrentUser() {
        return this.currentUser;
    }

    public isAuthenticated(): boolean {
        return this.currentUser !== null;
    }

    public isAuthenticatedState(): Observable<boolean> {
        // return this.firebaseAuthService.getAuthorizedUserState().pipe(
        //   map((currentUser: AuthorizedUserModel) => {
        //     this.currentUser = currentUser;
        //     return currentUser !== null;
        //   })
        // );
        const localStorageAuthData: any = localStorage.getItem(LocalStorageKeyConstant.AUTH_KEY);
        if (localStorageAuthData) {
            const data: any = JSON.parse(localStorageAuthData);
            if (data && data.token && data.token.payload && data.token.payload.exp) {
                const now = moment().unix();
                return of(now < data.token.payload.exp);
            }
        }

        return of(false);
    }

    public async login(loginModel: LoginModel) {
        const signInUser = new SignInModel();
        signInUser.username = loginModel.username;
        signInUser.password = loginModel.password;
        signInUser.rememberMe = false;
        const gsUser = await this.cognitoService.signIn(signInUser);
        if (gsUser) {
            this.currentUser = new AuthorizedUserModel({
                gsUser: gsUser
            });
        }

        return this.currentUser;
    }

    public async logout() {
        // await this.firebaseAuthService.logout();
        this.currentUser = null;
    }

    public getUserProfile(): Observable<any> {
        return super.get('users/profile/').pipe(
            map((data: any) => {
                return new GetUserProfileOutputApiModel(data);
            })
        );
    }
}
