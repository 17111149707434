import { PrivatePageBase } from '@framework/base/private-base/private-page.basse';
import { BreadcrumService } from './../../services/breadcrum.service';
import { BreadcrumModel } from './../../models/breadcrum/breadcrum.model';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-breadcrum',
    templateUrl: './breadcrum.component.html',
    styleUrls: ['./breadcrum.component.scss']
})
export class BreadcrumComponent extends PrivatePageBase {
    breadcrums: BreadcrumModel;
    private breadcrumSubscription: Subscription;

    constructor(private breadcrumService: BreadcrumService) {
        super();
    }

    subscribe(): void {
        this.breadcrumSubscription = this.breadcrumService.breadcrums.subscribe((data: BreadcrumModel) => this.breadcrums = data);
    }

    protected onInit(): void {
        this.subscribe();
    }
    protected onDestroy(): void {
        if (this.breadcrumSubscription) {
            this.breadcrumSubscription.unsubscribe();
        }
    }
    protected onChanges(changes: import("@angular/core").SimpleChanges): void {
    }
    protected onDoCheck(): void {
    }
    protected onAfterContentInit(): void {
    }
    protected onAfterContentChecked(): void {
    }
    protected onAfterViewInit(): void {
    }
    protected onAfterViewChecked(): void {
    }
}
