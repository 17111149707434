import { ColumnModel } from './column.model';

export class CommandColumnModel extends ColumnModel {
    
    public onClicked: (...agrs) => void;
    public bindText:  (...agrs) => void;
    public text: string;
    public arrayTextBinding: Array<string | number>;
    public css: object;
    
    constructor(init?: Partial<CommandColumnModel>) {
        super(init);
        Object.assign(this, init);
    }
}