import { Component, Input } from '@angular/core';
import { AbstractControlDirective, AbstractControl } from '@angular/forms';
import { of, Observable } from 'rxjs';

@Component({
    selector: 'app-show-error',
    templateUrl: './show-error.component.html',
    styleUrls: ['./show-error.component.scss']
})
export class ShowErrorComponent {
    shouldShowErrors(): boolean {
        return this.control && this.control.errors && (this.control.dirty || this.control.touched || this.submitted);
    }

    listOfErrors(): string[] {
        return Object.keys(this.control.errors).map(field => this.getMessage(field, this.control.errors[field]));
    }

    private getMessage(type: string, params: any) {
        return this.errorMessages[type](params);
    }

    private readonly errorMessages = {
        required: () => of('Bạn phải điền vào trường này'),
        minlength: (length: number) => of(`Tối thiểu phải có ${length} ký tự`),
        maxlength: (length: number) => of(`Tối đa chỉ có ${length} ký tự`),
        email: () => of('Vui lòng nhập đúng định dạng email')
    }

    @Input() control: AbstractControlDirective | AbstractControl;
    @Input() submitted: boolean;
}
