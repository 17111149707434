import { createAction, props } from '@ngrx/store';
import * as inputApi from '@app/modules/shared/models/api-models/input';
import * as outputApi from '@app/modules/shared/models/api-models/output';


export const getAppSetting = createAction(
    '[App Setting] Get App Setting',
    props<{input: inputApi.GetAppSettingInputApiModel}>()
);

export const getAppSettingSuccess = createAction(
    '[App Setting] Get App Setting Success',
    props<{output: outputApi.GetAppSettingOutputApiModel}>()
);

export const getAppSettingFail = createAction(
    '[App Setting] Get App Setting Fail',
    props<{input: inputApi.GetAppSettingInputApiModel, error: any}>()
);