import { Callback } from './../models/uploader-models/callbac.model';
import { Injectable } from '@angular/core';
import { s3 } from 'fine-uploader/lib/core/s3';
import { noop } from 'rxjs';
import { environment } from '@app/env/environment';
import { UtilityHelper } from '@framework/utilities';
import { UploadedFileInfoModel } from '../models/uploader-models/uploaded-file-info.model';
import { UploaderConfig } from '../models/uploader-models/uploader-config.model';
import { LocalStorageKeyConstant } from '@app/modules/shared/constants/localstorage-key.constant';

@Injectable()
export class UploaderService {
    private s3KeyPrefix: string;// = `mediafiles/company`;
    private path: string;
    private uploadedFiles: UploadedFileInfoModel[] = [];
    private token: string;

    constructor(private utility: UtilityHelper) {
        const dataLocalStorage = localStorage.getItem(LocalStorageKeyConstant.AUTH_KEY);
        const dataJson = JSON.parse(dataLocalStorage);
        if (dataJson && dataJson.token && dataJson.token.jwtToken) {
            this.token = dataJson.token.jwtToken;
        }
    }

    // Private functions
    private buildPath(config: UploaderConfig) {
        this.path = `mediafiles/company/${config.companyId}`;
        this.s3KeyPrefix = `${environment.aws.s3.path}/${this.path}`;
    }

    private onUploadAllComplete() {
        // Clear uploaded files list
        this.uploadedFiles = [];
    }

    // Public functions
    public s3Uploader(config: UploaderConfig, callbacks: Callback) {
        this.buildPath(config);
        let signature: any = {};
        if (config.isAnonymous) {
            signature.endpoint = `${environment.endpointUrl}/users/s3-anonymous-signature/`;
        }
        else {
            signature.endpoint = `${environment.endpointUrl}/users/s3-signature/`;
            signature.customHeaders = {
                "Authorization": `Bearer ${this.token}`
            }
        }

        const uploader = new s3.FineUploaderBasic({
            button: config.button,
            debug: false,
            autoUpload: config.autoUpload,
            multiple: config.multiple,
            validation: {
                allowedExtensions: config.validate.allowedExtensions,
                sizeLimit: config.validate.sizeLimit,
                image: {
                    minHeight: config.validate.imageMinHeight || 0,
                    minWidth: config.validate.imageMinWidth || 0
                }
            },
            messages: {
                minHeightImageError: config.messages.imageDimensionError,
                minWidthImageError: config.messages.imageDimensionError,
                sizeError: config.messages.maxSizeError,
                typeError: config.messages.typeError
            },
            region: environment.aws.s3.region,
            request: {
                endpoint: environment.aws.s3.uploadEnpoint,
                accessKey: environment.aws.accessId
            },
            signature: signature,
            cors: {
                expected: true,
                sendCredentials: true
            },
            objectProperties: {
                acl: 'public-read',
                key: (id) => {
                    const uuid = uploader.getUuid(id);
                    const fileInfo = uploader.getFile(id);
                    const fileext = fileInfo.name && fileInfo.name.indexOf('.') > -1 ? fileInfo.name.split('.').pop() : fileInfo.type.split('/').pop();
                    const filename = `${uuid}.${fileext}`;

                    this.uploadedFiles.push({
                        name: filename,
                        type: fileInfo.type,
                        id: id
                    });

                    return `${this.s3KeyPrefix}/${filename}`
                }
            },
            // scaling: {
            //     sizes: [
            //         { name: 'medium', maxSize: 300 }
            //     ]
            // },
            callbacks: {
                onSubmit: callbacks.onSubmit || noop,
                onSubmitted: callbacks.onSubmitted || noop,
                onComplete: callbacks.onComplete || noop,
                onAllComplete: (successful, failed) => {
                    this.onUploadAllComplete();
                    if (callbacks.onAllComplete)
                        callbacks.onAllComplete(successful, failed)
                },
                onCancel: callbacks.onCancel || noop,
                onUpload: callbacks.onUpload || noop,
                onUploadChunk: callbacks.onUploadChunk || noop,
                onUploadChunkSuccess: callbacks.onUploadChunkSuccess || noop,
                onResume: callbacks.onResume || noop,
                onProgress: callbacks.onProgress || noop,
                onTotalProgress: callbacks.onTotalProgress || noop,
                onError: callbacks.onError || noop,
                onSessionRequestComplete: callbacks.onSessionRequestComplete || noop,
                onValidate: callbacks.onValidate || noop,
                onValidateBatch: callbacks.onValidateBatch || noop
            }
        });

        return uploader;
    }

    public getUploadedFile(id: number) {
        const file = this.uploadedFiles.find(f => f.id === id);
        return file;
    }
    // Properties
    get Path() {
        return `/${this.path}`;
    }
}