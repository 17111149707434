import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
@Injectable()
export class FormHelper {
  constructor() { }

  public unsubscription(component: any) {
    for (let propertyName in component) {
      const propertyValue = component[propertyName];
      if (propertyValue && typeof propertyValue['unsubscribe'] === 'function') {
        propertyValue.unsubscribe();
      }
    }
  }

  public markFormGroupDirty(form: FormGroup) {
    Object.values(form.controls).forEach(control => {
      control.markAsDirty();

      if ((control as any).controls) {
        this.markFormGroupDirty(control as FormGroup);
      }
    });
  }

  public updateFormGroupValidity(form: FormGroup) {
    Object.values(form.controls).forEach(control => {
      control.updateValueAndValidity();

      if ((control as any).controls) {
        this.updateFormGroupValidity(control as FormGroup);
      }
    });
  }
}
