import { Subscription, fromEvent } from 'rxjs';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ComponentBase } from 'framework/base/components/component/component.base';
import { Component, SimpleChanges, ViewEncapsulation, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { debounceTime, tap } from 'rxjs/operators';

@Component({
    selector: 'grid-tools',
    templateUrl: './grid-tools.component.html',
    styleUrls: ['./grid-tools.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class GridToolsComponent extends ComponentBase {
    @Input('totalCount') totalCount: number = 0;
    @Input('filterList') listFilters: Array<any> = [];
    @Input('filterSearch') filterSearch: Array<any> = [];
    @Input('itemPerPage') itemPerPage: number = 10;
    @Output('searchObject') searchObject: EventEmitter<any> = new EventEmitter<any>(null);
    @Output('filterChange') filterChange: EventEmitter<any> = new EventEmitter<any>(null);
    @ViewChild('listInputSearch', { static: true }) listInputSearch: ElementRef;
    public showDropbox: boolean = false;

    formFilter: FormGroup;
    private defaultFilter: any = {
        itemPerPage: 20,
        filters: [],
        keyword: null,
        searches: []
    }

    constructor(private fb: FormBuilder) {
        super();
    }

    public changeDropboxStatus(status: boolean): void {
        this.showDropbox = status;
    }

    protected onInit(): void {
        this.initFormFilter();
    }

    private initFormFilter(): void {
        this.formFilter = this.fb.group({
            itemPerPage: [this.defaultFilter.itemPerPage, Validators.required],
            filters: this.fb.array(this.defaultFilter.filters),
            keyword: [this.defaultFilter.keyword],
            searches: this.fb.array(this.defaultFilter.searches)
        });
        if(this.formFilter) {
            this.changeFilters(this.listFilters);
            this.changeFiltersSearch(this.filterSearch)
        }
    }

    protected onDestroy(): void {
    }

    protected onChanges(changes: SimpleChanges): void {
        this.showDropbox = true;
        if(this.formFilter) {
            this.changeFilters(this.listFilters);
        }
    }
    protected onDoCheck(): void {
    }
    protected onAfterContentInit(): void {
    }
    protected onAfterContentChecked(): void {
    }
    protected onAfterViewInit(): void {
        fromEvent(this.listInputSearch.nativeElement, 'keyup').pipe(
            tap(() => this.showDropbox = false),
            debounceTime(1000)
        ).subscribe((element: any) => {
            const ref: any = element.target.getAttribute('key');
            if (ref) {
                const refInFilterSearch: Array<any> = this.filterSearch.filter((item: any) => {
                    return item.controlName == ref;
                });
                if (refInFilterSearch && refInFilterSearch.length > 0 && refInFilterSearch[0].onEmit) {
                    refInFilterSearch[0].onEmit(element.target.value);
                }
            }
        })
    }
    protected onAfterViewChecked(): void {
    }

    private changeFiltersSearch(arrayFilters: Array<any>): void {
        if(this.formFilter.controls['searches']) {
            let filtersSearchInForm: FormArray = this.formFilter.get('searches') as FormArray;
            filtersSearchInForm.clear();
            const formValue: any = this.formFilter.value;
            this.defaultFilter.searches = arrayFilters.map((item: any) => {
                filtersSearchInForm.push(this.createItem({ columnEmit: item.columnEmit, defaultNull: true }));
                return {
                    columnEmit: item.columnEmit,
                    value: item.defaultValue
                }
            });
            const data = { ...this.defaultFilter, keyword: formValue.keyword }
            this.formFilter.patchValue(data);
        }
    }

    private changeFilters(araryFilters): void {
        if(this.formFilter.controls['filters']) {
            let filtersInForm: FormArray = this.formFilter.get('filters') as FormArray;
            filtersInForm.clear();
            const formValue: any = this.formFilter.value;
            this.defaultFilter.filters = araryFilters.map((item: any) => {
                filtersInForm.push(this.createItem(item));
                if(item.defaultNull) {
                    if(item.activeValue) {
                        return { columnEmit: item.columnEmit, value: item.activeValue }
                    } else {
                        return { columnEmit: item.columnEmit, value: "" }
                    }
                } else {
                    return { 
                        columnEmit: item.columnEmit,
                        value: item.activeValue ? item.activeValue : (item.data[0] ? item.data[0][item.columnValue]: "")
                    }
                }
            });
            this.showDropbox = false;
            const data = { ...this.defaultFilter, keyword: formValue.keyword }
            this.formFilter.patchValue(data);
        }
    }

    private createItem(initValue: any): FormGroup {
        return this.fb.group({
            columnEmit: initValue.columnEmit,
            value: initValue.defaultNull ? "" : initValue.data[0][initValue.columnValue]
        });
    }

    public emitDataToSearch(): void {
        this.mapFilterToResponseParent(this.formFilter.value);
    }

    private mapFilterToResponseParent(data): any {
        let result: any = {
            limit: data.itemPerPage
        };
        if(data.keyword) {
            result['keyword'] = data.keyword;
        }
        if(data.filters && data.filters.length > 0) {
            data.filters.map((item: any) => {
                if(item.columnEmit)
                result[item.columnEmit] = item.value;
            });
        }

        this.searchObject.emit(result);
    }

    public resetForm(): void {
        this.formFilter.patchValue(this.defaultFilter);
    }
    
    public changeLimit(event): void {
        this.emitDataToSearch();
    }

    public changeData(event: any, index: number) {
        let result: any = {
            value: event.target.value,
            index,
            params: {}
        };
        result['params'][event.target.name] = event.target.value;
        this.filterChange.emit(result);
    }

    public trackbyFn(index: number, item: any) {
        return item.id
    }

    public clickChildItem(index: number, item: any, columValue: string) {
        this.defaultFilter.searches[index]['value'] = item[columValue]
        this.filterSearch[index].onClickChildItem(item);
        this.showDropbox = false;
    }

    public clickInputSearch(event: any) {
        this.showDropbox = true;
    }
}
