import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { LoDashStatic } from 'lodash';

@Injectable()
export class LodashHelper {
	public lodash: LoDashStatic;
	constructor(){
		this.lodash = _;
	}
}