// https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_InitiateAuth.html

export class CognitoErrorCode {
    public static readonly AliasExistsException = 'AliasExistsException';
    public static readonly CodeMismatchException = 'CodeMismatchException';
    public static readonly ExpiredCodeException = 'ExpiredCodeException';
    public static readonly InvalidParameterException = 'InvalidParameterException';
    public static readonly LimitExceededException = 'LimitExceededException';
    public static readonly NotAuthorizedException = 'NotAuthorizedException';
    public static readonly ResourceNotFoundException = 'ResourceNotFoundException';
    public static readonly TooManyFailedAttemptsException = 'TooManyFailedAttemptsException';
    public static readonly TooManyRequestsException = 'TooManyRequestsException';
    public static readonly UserNotFoundException = 'UserNotFoundException';
    public static readonly UserNotConfirmedException = 'UserNotConfirmedException';
    public static readonly PasswordResetRequiredException = 'PasswordResetRequiredException';
    public static readonly UsernameExistsException = 'UsernameExistsException';
    public static readonly GeneralError = 'GeneralError';
}