export class Company {
    public id: number;
    public name: string;
    public domain: string;
    public user: number;
    public status: string;
    public draft: number;
    public published: number;
    public last_news_sent: number | null;
    constructor(init?: Partial<Company>) {
        Object.assign(this, init);
    }
}