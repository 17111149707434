import { ComponentBase } from 'framework/base/components/component/component.base';
import { Component, SimpleChanges, ViewEncapsulation, Input, ChangeDetectorRef } from '@angular/core';
import { ColumnModel, GridModel, CommandColumnModel } from './models';

@Component({
    selector: 'grid-control',
    templateUrl: './grid-control.component.html',
    styleUrls: ['./grid-control.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class GridControlComponent extends ComponentBase {

    @Input('grid')
    grid: GridModel<any> = new GridModel<any>();

    public columns: ColumnModel[] = [];
    public data: Array<any> = [];
    public commandColumns: CommandColumnModel[] = [];

    constructor() {
        super();
    }

    protected onInit(): void {
        this.columns = this.grid.columns || [];
        this.data = this.grid.data || [];
        this.commandColumns = this.grid.commandColumns || [];
    }
    protected onDestroy(): void {
    }
    protected onChanges(changes: SimpleChanges): void {
    }
    protected onDoCheck(): void {
    }
    protected onAfterContentInit(): void {
    }
    protected onAfterContentChecked(): void {
    }
    protected onAfterViewInit(): void {
    }
    protected onAfterViewChecked(): void {
    }
    public setDataSource(data: Array<any> = []) {
        this.grid.data = data;
        this.data = data;
    }

    public setColumns(columns: ColumnModel[]) {
        this.grid.columns = columns;
        this.columns = columns;
    }

    public setCommandColumns(columns: CommandColumnModel[]) {
        this.grid.commandColumns = columns;
        this.commandColumns = columns;
    }

    public trackColumnByFn(index: number, item: ColumnModel) {
        return item.name;
    }

    public trackRowByFn(index: number, item: any) {
        return item[this.grid.primaryColumn];
    }
}
