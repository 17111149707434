import { Injectable, Inject } from '@angular/core';
import { FileInfoModel } from '../models/file-info/file-info.model';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class FileHelper {
  private fileReader: any;
  constructor(@Inject(DOCUMENT) private document: Document) {
    if (typeof FileReader !== 'undefined')
      this.fileReader = new FileReader();
  }

  public getFileInfo(file: File) {
    const promise: Promise<any> = new Promise((resolve, reject) => {
      this.fileReader.onload = (fileData: any) => {
        resolve(fileData.target.result);
      };
    });
    this.fileReader.readAsDataURL(file);
    const fileInfo: FileInfoModel = new FileInfoModel(file.name, file.size, file.type, promise);
    return fileInfo;
  }

  public getFileExtention(fileName: string) {
    if (fileName) {
      const index = fileName.lastIndexOf('.');
      if (index === -1) {
        return '';
      }
      return fileName.substring(index);
    }
    return '';
  }

  public getFileNameFromUrl(fileName: string) {
    if (fileName) {
      const index = fileName.lastIndexOf('/');
      if (index === -1) {
        return '';
      }
      return fileName.substring(index + 1, fileName.length);
    }
    return '';
  }

  public b64ToBlob(b64Data, contentType, sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  public b64ToFile(b64Data, filename, contentType, sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const file = new File(byteArrays, filename, { type: contentType });
    return file;
  }

  public getVideoDuration(dataBlob: Blob): Promise<number> {
    return new Promise((resolve, reject) => {
      // Create URL of  blob
      const url = URL.createObjectURL(dataBlob);
      const video = this.document.createElement('video');
      video.preload = 'metadata';
      video.addEventListener('loadedmetadata', () => {
        if (video.duration === Infinity) {
          video.currentTime = 24 * 60 * 60;
          video.addEventListener('timeupdate', () => {
            resolve(video.duration);
            (URL || webkitURL).revokeObjectURL(url);
          })
        } else {
          resolve(video.duration);
          (URL || webkitURL).revokeObjectURL(url);
        }
      });
      video.src = url;
    });
  }

  public getUrlFromBlog(dataBlob: Blob): string {
    return URL.createObjectURL(dataBlob);
  }
}
