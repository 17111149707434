import { NgModule, ModuleWithProviders } from '@angular/core';
import { CognitoAuthService } from './cognito-auth.service';
import { OAuthDialogService } from './core';

@NgModule({    
    imports: [],
    exports: []    
})
export class CognitoModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: CognitoModule,
            providers: [CognitoAuthService, OAuthDialogService]
        }
    }
}