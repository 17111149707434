import { Component, SimpleChanges, ViewEncapsulation, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { InputControlBase } from '../base/input-control-base/input-control.base';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'input-text-control',
  templateUrl: './input-text-control.component.html',
  styleUrls: ['./input-text-control.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTextControlComponent),
      multi: true
    }
  ]
})
export class InputTextControlComponent extends InputControlBase<string> {
  
  constructor(){
    super();
  }
  protected onInit(): void {
    this.initCss();
  }  
  protected onDestroy(): void {
  }
  protected onChanges(changes: SimpleChanges): void {
  }
  protected onDoCheck(): void {
  }
  protected onAfterContentInit(): void {
  }
  protected onAfterContentChecked(): void {
  }
  protected onAfterViewInit(): void {
  }
  protected onAfterViewChecked(): void {    
  }

  private initCss() {
    this.defaultCss += ` ${this.css}`;
  }

  public inputChanged($event: any) {
    this.onChangeFn(this.value);
    this.onChanged.emit($event);
  }
  
}
