
import { initialAppSettingState, AppSettingState } from '../state/app-setting.state';

import { appSettingActions } from '../actions';
import { Action, createReducer, on } from '@ngrx/store';


const reducer = createReducer(
    initialAppSettingState,
    on(appSettingActions.getAppSetting, (state: AppSettingState, { input }) => {
        return {
            ...state,
            isAppSettingLoading: true            
        }
    }),
    on(appSettingActions.getAppSettingSuccess, (state: AppSettingState, {output}) => {
        return {
            ...state,
            isAppSettingLoading: false,
            isAppSettingLoaded: true,
            appSetting: output
        }
    })
);

export function appSettingReducer(state: AppSettingState, action: Action) {
    return reducer(state, action);
}