import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UploadService } from './upload.service';
import { ComponentBase } from '@framework/base/components/component/component.base';

@Component({
    selector: 'app-upload',
    templateUrl: './upload.component.html',
    styleUrls: ['./upload.component.scss']
})
export class UploadComponent extends ComponentBase{
    @Input() multi: boolean;
    @Input('apiUrl') apiUrl: string;
    @Input('element') element: string = 'fileUpload';
    @Input('percentage') percentage: number;
    @Input('defaultImage') defaultImage: string = '/assets/img/default-image.png';
    @Input() classStyle: string;
    @Output() fileUploaded = new EventEmitter<any[]>();
    public progress: { percentage: number } = { percentage: -1 };
    public arrayFileUpload: any = [];
    public url: string = '';
    public MAX_FILE_SIZE_UPLOAD = 2;

    constructor(private uploadService: UploadService) { super()}

    protected onInit(): void {
    }
    protected onDestroy(): void {
    }
    protected onChanges(changes: import("@angular/core").SimpleChanges): void {
    }
    protected onDoCheck(): void {
    }
    protected onAfterContentInit(): void {
    }
    protected onAfterContentChecked(): void {
    }
    protected onAfterViewInit(): void {
    }
    protected onAfterViewChecked(): void {
    }

    public selectFile(event) {
        this.progress.percentage = 0;
        const selectedFiles: Array<File> = event.target.files;
        if (selectedFiles && selectedFiles.length > 0) {
            this.uploadLocal(selectedFiles);
            const filesToEmit: Array<File> = this.filterFileSize(selectedFiles);
            this.fileUploaded.emit(filesToEmit);
        }
    }

    public filterFileSize(arrayFile: Array<File>): Array<File> {
        const result: Array<File> = [];
        if(arrayFile && arrayFile.length > 0) {
            for(let i = 0; i < arrayFile.length; i++) {
                const file = arrayFile[i];
                const fileSize = file.size/1024/1024;
                if(fileSize <= this.MAX_FILE_SIZE_UPLOAD) {
                    result.push(file);
                }
            }
        }

        return result;
    }

    private uploadLocal(selectedFiles) {
        if (selectedFiles.length > 0) {
            for (var i = 0; i < selectedFiles.length; i++) {
                const reader: any = new FileReader();
                const file = selectedFiles[i];
                const image = new Array();
                const fileSize = file.size/1024/1024;
                reader.readAsDataURL(selectedFiles[i]);
                reader.onload = event => {
                    image['url'] = event.target.result;
                };
                image['filename'] = file.name;
                image['filetype'] = file.type;
                image['value'] = reader.result;
                if(fileSize > this.MAX_FILE_SIZE_UPLOAD) {
                    image['bigSize'] = 1;
                }
                if (this.multi) {
                    this.arrayFileUpload.push(image);
                } else {
                    this.arrayFileUpload[i] = image;
                }
            }
        }
    }

    public deleteImageUpload(index: number) {
        this.progress.percentage = -1;
        this.arrayFileUpload.splice(index, 1);
    }
}
