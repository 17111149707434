import { Injectable } from '@angular/core';

@Injectable()
export class EncodeHelper {

    constructor() { }

    public base64(iData: any) {
        if (!iData) return iData;
        const iDataAsString = JSON.stringify(iData);
        return btoa(iDataAsString);
    }

    public decodeBase64<T>(base64String: string): T {
        const decodedString = atob(base64String);
        const decodedJson = JSON.parse(decodedString);
        return decodedJson as T;
    }
}
