import { Component, SimpleChanges, ViewEncapsulation, forwardRef } from '@angular/core';
import { InputControlBase } from '../base/input-control-base/input-control.base';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input-email-control',
  templateUrl: './input-email-control.component.html',
  styleUrls: ['./input-email-control.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputEmailControlComponent),
      multi: true
    }
  ]
})
export class InputEmailControlComponent extends InputControlBase<string> {
  
  constructor(){
    super();
  }
  protected onInit(): void {
    this.initCss();
  }  
  protected onDestroy(): void {
  }
  protected onChanges(changes: SimpleChanges): void {
  }
  protected onDoCheck(): void {
  }
  protected onAfterContentInit(): void {
  }
  protected onAfterContentChecked(): void {
  }
  protected onAfterViewInit(): void {
  }
  protected onAfterViewChecked(): void {    
  }

  private initCss() {
    this.defaultCss += ` ${this.css}`;
  }

  public inputChanged($event: any) {
    this.onChangeFn(this.value);
    this.onChanged.emit($event);
  }
}
