
import { ModelBase } from '@framework/models/base/base.model';
import { ColumnModel } from './column.model';
import { CommandColumnModel } from './command-column.model';

export class GridModel<T> extends ModelBase {
    public primaryColumn: string = 'id';
    public data: T[];
    public columns: ColumnModel[] = [];
    public commandColumns: CommandColumnModel[] = [];
    constructor(init?: Partial<GridModel<T>>) {
        super(init);
        Object.assign(this, init);
    }
}