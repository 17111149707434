import { CreateCompanyInputApiModel } from './../models/api-models/input/create-company-input.api-model';
import { SeachCompaniesInputApiModel } from './../models/api-models/input/search-companies-input.api-model';
import { createAction, props } from '@ngrx/store';
import { Company } from '../models/api-models/output/company';
import { CompanyValidateFieldOutputApiModel } from '@app/modules/company/models/api-models/output/company-validate-field-output.api-model';

export enum CompanyActionsType {
    SEARCH_COMPANIES = '[Company] Search Companies',
    SEARCH_COMPANIES_SUCCESS = '[Company] Search Companies Success',
    SEARCH_COMPANIES_FAIL = '[Company] Search Companies Fail',
    VALIDATE_FIELD_EXIST_COMPANY = '[Company] Validate Field Exist Company',
    VALIDATE_FIELD_EXIST_COMPANY_SUCCESS = '[Company] Validate Field Exist Company Success',
    VALIDATE_FIELD_EXIST_COMPANY_FAIL = '[Company] Validate Field Exist Company Failure',
    CREATE_NEW_COMPANY = '[Company] Create New Company',
    CREATE_NEW_COMPANY_SUCCESS = '[Company] Create New Company Success',
    CREATE_NEW_COMPANY_FAIL = '[Company] Create New Company Fail',
    GET_COMPANY_DETAIL = '[Company] Get Company Detail',
    GET_COMPANY_DETAIL_SUCCESS = '[Company] Get Company Detail Success',
    GET_COMPANY_DETAIL_FAIL = '[Company] Get Company Detail Failure',
    UPDATE_COMPANY_INFO = '[Company] Update Company Info',
    UPDATE_COMPANY_INFO_SUCCESS = '[Company] Update Company Info Success',
    UPDATE_COMPANY_INFO_FAIL = '[Company] Update Company Info Fail',
    GET_COMPANY_BY_NAME = '[Company] Get Company By Name',
    GET_COMPANY_BY_NAME_SUCCESS = '[Company] Get Company By Name Success',
    GET_COMPANY_BY_NAME_FAIL = '[Company] Get Company By Name Failure'
}

export const SearchListCompanies = createAction(
  CompanyActionsType.SEARCH_COMPANIES,
  props<{payload: SeachCompaniesInputApiModel}>()
);

export const SearchListCompaniesSuccess = createAction(
  CompanyActionsType.SEARCH_COMPANIES_SUCCESS,
  props<{ payload: { data: Company[], count: number }}>()
);

export const SearchListCompaniesFail = createAction(
  CompanyActionsType.SEARCH_COMPANIES_FAIL,
  props<{ payload: SeachCompaniesInputApiModel, error: string }>()
);

export const ValidateFieldExistCompany = createAction(
  CompanyActionsType.VALIDATE_FIELD_EXIST_COMPANY,
  props<{ payload: { column: string, value: string | number, exceptId?: number } }>()
);

export const ValidateFieldExistCompanySuccess = createAction(
  CompanyActionsType.VALIDATE_FIELD_EXIST_COMPANY_SUCCESS,
  props<{ payload: CompanyValidateFieldOutputApiModel }>()
);

export const ValidateFieldExistCompanyFail = createAction(
  CompanyActionsType.VALIDATE_FIELD_EXIST_COMPANY_FAIL,
  props<{ payload: { column: string, value: string | number }, error: string }>()
);

export const CreateNewCompany = createAction(
    CompanyActionsType.CREATE_NEW_COMPANY,
    props<{ payload: CreateCompanyInputApiModel }>()
);

export const CreateNewCompanySuccess = createAction(
    CompanyActionsType.CREATE_NEW_COMPANY_SUCCESS,
    props<{ payload: any }>()
);

export const CreateNewCompanyFail = createAction(
    CompanyActionsType.CREATE_NEW_COMPANY_FAIL,
    props<{ payload: CreateCompanyInputApiModel, error: string }>()
);

export const GetCompanyDetail = createAction(
    CompanyActionsType.GET_COMPANY_DETAIL,
    props<{ payload: { id: number } }>()
);

export const GetCompanyDetailSuccess = createAction(
    CompanyActionsType.GET_COMPANY_DETAIL_SUCCESS,
    props<{ payload: any }>()
);

export const GetCompanyDetailFailure = createAction(
    CompanyActionsType.GET_COMPANY_DETAIL_FAIL,
    props<{ payload: any, error: string }>()
);

export const UpdateCompanyInfo = createAction(
    CompanyActionsType.UPDATE_COMPANY_INFO,
    props<{ payload: { id: number, data: any } }>()
);

export const UpdateCompanyInfoSuccess = createAction(
    CompanyActionsType.UPDATE_COMPANY_INFO_SUCCESS,
    props<{ payload: { data: any } }>()
);

export const UpdateCompanyInfoFail = createAction(
    CompanyActionsType.UPDATE_COMPANY_INFO_FAIL,
    props<{ payload: { data: any }, error: string }>()
);

export const GetCompanyByName = createAction(
    CompanyActionsType.GET_COMPANY_BY_NAME,
    props<{ payload: { name: string } }>()
);

export const GetCompanyByNameSuccess = createAction(
    CompanyActionsType.GET_COMPANY_BY_NAME_SUCCESS,
    props<{ payload: {id: number, name: string}[] }>()
);

export const GetCompanyByNameFail = createAction(
    CompanyActionsType.GET_COMPANY_BY_NAME_FAIL,
    props<{ payload: { name: string }, error: string }>()
);