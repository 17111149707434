import { catchError } from 'rxjs/operators';
import { LocalStorageKeyConstant } from './../../shared/constants/localstorage-key.constant';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { AuthState } from '@app/state/auth/state/auth.state';
import { selectUserContext } from '@app/state/auth/selectors';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {
    private user$: Observable<any>;
    private userToken: string;

    constructor(private store: Store<AuthState>) {
        this.user$ = store.select(selectUserContext);
        // this.user$.subscribe((user: any) => {
        //     if(user && user.gsUser && user.gsUser.signInUserSession && user.gsUser.signInUserSession.idToken && user.gsUser.signInUserSession.idToken.jwtToken) {
        //         this.userToken = user.gsUser.signInUserSession.idToken.jwtToken;
        //     }
        // });
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.userToken) {
            const dataLocalStorage: any = localStorage.getItem(LocalStorageKeyConstant.AUTH_KEY);
            const authData: any = JSON.parse(dataLocalStorage);
            if (authData && authData.token && authData.token.jwtToken) {
                this.userToken = authData.token.jwtToken;
            }
        }
        let headers = {};
        let token = this.userToken;
        if (!request.url.endsWith('/auth/login') && !request.url.endsWith('/auth')) {
            headers = Object.assign(headers, { Authorization: `Bearer ${token}` });
        }
        request = request.clone({
            setHeaders: headers
        });
        return next.handle(request);
    }
}


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private router: Router) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((response: any) => {
                if (
                    response instanceof HttpErrorResponse &&
                    (response.status === 401 || response.status === 403)
                ) {
                    localStorage.removeItem(LocalStorageKeyConstant.AUTH_KEY);
                    this.router.navigateByUrl('/');
                }
                return throwError(response);
            })
        );
    }
}