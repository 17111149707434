import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { authActions } from '../actions';
import { map, switchMap, catchError, tap } from 'rxjs/operators';
import { AuthHttpService } from '@app/modules/auth/services';
import { from, of } from 'rxjs';
import {
    LoginOutputApiModel,
    LogoutOutputApiModel,
    GetUserProfileOutputApiModel
} from '@app/modules/shared/models/api-models/output';
import { LoginModel } from '@framework/models/users/login.model';
import { AuthorizedUserModel } from '@framework/models/users/authorized-user.model';
import { Router } from '@angular/router';
import { environment } from '@app/env/environment';


@Injectable()
export class AuthEffects {
    constructor(private actions$: Actions,
        private authService: AuthHttpService,
        private router: Router) { }

    login$ = createEffect(() =>
        this.actions$.pipe(
            ofType(authActions.login),
            switchMap(({ input }) => {
                const loginModel = new LoginModel({
                    username: input.username,
                    password: input.password
                });
                return from(this.authService.login(loginModel)).pipe(
                    map((authorizedUser: AuthorizedUserModel) => {
                        const output: LoginOutputApiModel = new LoginOutputApiModel({
                            userContext: authorizedUser
                        });
                        return authActions.loginSuccess({ output });
                    }),
                    catchError((error: any) => {
                        return of(authActions.loginFail({ input, error }));
                    })
                );
            })
        )
    );

    loginSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(authActions.loginSuccess),
            tap(() => {
                const auth = environment.auth;
                this.router.navigateByUrl(auth.redirectUrl);
            })
        )
        , { dispatch: false });

    logout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(authActions.logout),
            switchMap(({ input }) => {

                return from(this.authService.logout()).pipe(
                    map(() => {
                        const output: LogoutOutputApiModel = new LogoutOutputApiModel();
                        return authActions.logoutSuccess({ output });
                    }),
                    catchError((error: any) => {
                        return of(authActions.logoutFail({ input, error }));
                    })
                );
            })
        )
    );

    getUserProfile$ = createEffect((): any => {
        return this.actions$.pipe(
            ofType(authActions.getProfile),
            switchMap(() => {
                return this.authService.getUserProfile().pipe(
                    map((input: GetUserProfileOutputApiModel) => {
                        return authActions.getProfileSuccess({ input })
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return of(authActions.getProfileFail({ error: error.message }));
                    })
                );
            })
        );
    });
}
