import { HttpClient } from '@angular/common/http';
import { environment } from '@app/env/environment';
import { ModelBase } from '@framework/models/base/base.model';

export abstract class HttpService {
    private endpoint: string = '';
    constructor(protected httpClient: HttpClient,
        private segment?: string) {
        const apiUrl = environment.apiUrl;
        if (this.segment && this.segment != '') {
          this.endpoint = `${apiUrl}/${this.segment}`;
        }
        else {
          this.endpoint = `${apiUrl}`;
        }
    }
    protected get<T extends ModelBase>(url: string) {
        return this.httpClient.get<T>(`${this.endpoint}/${url}`);
    }
    protected post<T extends ModelBase>(url: string, data: any) {
        return this.httpClient.post<T>(`${this.endpoint}/${url}`, data);
    }
    protected put<T extends ModelBase>(url: string, data: any) {
        return this.httpClient.put<T>(`${this.endpoint}/${url}`, data);
    }
    protected patch<T extends ModelBase>(url: string, data: any) {
        return this.httpClient.patch<T>(`${this.endpoint}/${url}`, data);
    }
    protected delete<T extends ModelBase>(url: string, data: any) {
        return this.httpClient.delete<T>(`${this.endpoint}/${url}`, data);
    }
    protected encodeData<T>(data: T): string {
        return Object.keys(data).map(function(key) {
            return [key, data[key]].map(encodeURIComponent).join("=");
        }).join("&");
    }
    protected revertParamsObjectToUrl(url: string, slugParamsObject?: Object): string {
        return `${ slugParamsObject ? url + '?' + this.encodeData(slugParamsObject) : url }`
    }
}
