import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { environment } from '@app/env/environment';

export enum MediaOriginEnum {
    Server = 1,
    Amazon = 2,
    Cloudinary = 3
}

@Pipe({ name: 'imageCloudinary' })
export class ImageCloudinaryPipe implements PipeTransform {

    constructor(private sanitizer: DomSanitizer){
        
    }

    transform(value: string, saveIn?: MediaOriginEnum, width?: number, height?: number): any {
        return this.generateImageSource(value, saveIn, width, height);
    }

    private generateImageSource(value: string, saveIn: MediaOriginEnum, width?: number, height?: number) {
        if (!value) return value;
        if (value.indexOf('blob') > -1) return this.sanitizer.bypassSecurityTrustResourceUrl(value);

        let cdnSrc = '';

        switch (saveIn) {
            case MediaOriginEnum.Server:
                cdnSrc = this.serverCase(value, width, height);
                break;
            case MediaOriginEnum.Amazon:
                cdnSrc = this.amazonCase(value, width, height);
                break;
            case MediaOriginEnum.Cloudinary:
                cdnSrc = this.cloudinaryCase(value, width, height);
                break;
            default:
                cdnSrc = this.defaultCase(value, width, height);
                break;
        }

        return cdnSrc;
    }

    private serverCase(value: string, width?: number, height?: number): string {
        let src = `${environment.cloudinary.cdnImageRootUrl}/fetch/c_fill,f_auto`;
        if (width > 0) {
            src += `,w_${width}`;
        }

        if (height > 0) {
            src += `,h_${height}`;
        }
        src = `${src}/${value}`;

        return src;
    }

    private amazonCase(value: string, width?: number, height?: number): string {
        const s3RelativePath = value.split(environment.cloudinary.s3RootUrl).pop();
        let src = `${environment.cloudinary.cdnImageRootUrl}/upload/c_fill,f_auto`;
        if (width > 0) {
            src += `,w_${width}`;
        }

        if (height > 0) {
            src += `,h_${height}`;
        }
        src = `${src}/${environment.cloudinary.keyPrefix}${s3RelativePath}`;

        return src;
    }

    private cloudinaryCase(value: string, width?: number, height?: number): string {
        const oldCloudinaryRelativePath = environment.cloudinary.convertToNewCdnUrl(value).split(`${environment.cloudinary.cdnImageRootUrl}/upload`).pop();
        let src = `${environment.cloudinary.cdnImageRootUrl}/upload/c_fill,f_auto`;
        if (width > 0) {
            src += `,w_${width}`;
        }

        if (height > 0) {
            src += `,h_${height}`;
        }
        src = `${src}${oldCloudinaryRelativePath}`;

        return src;
    }

    private defaultCase(value: string, width?: number, height?: number): string {
        let src = `${environment.cloudinary.cdnImageRootUrl}/fetch/c_fill,f_auto`;
        if (width > 0) {
            src += `,w_${width}`;
        }

        if (height > 0) {
            src += `,h_${height}`;
        }
        src = `${src}/${value}`;
        return src;
    }
}