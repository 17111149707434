import { BehaviorSubject, Observable } from 'rxjs';
import { BreadcrumModel } from './../models/breadcrum/breadcrum.model';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class BreadcrumService {
    private defaultBreadcrum: BreadcrumModel = {
        name: 'Dashboard',
        description: 'Control panel',
        levels: [
            {
                title: 'Dashboard',
                link: '/'
            }
        ]
    }
    private breadcrumSource: BehaviorSubject<BreadcrumModel> = new BehaviorSubject<BreadcrumModel>(this.defaultBreadcrum);
    public breadcrums: Observable<BreadcrumModel> = this.breadcrumSource.asObservable();

    constructor() { }

    public setBreadcrums(data: BreadcrumModel): void {
        this.breadcrumSource.next(data);
    }
}
