import { Injectable } from '@angular/core';
import { CognitoUserAttribute, ICognitoUserData, AuthenticationDetails, IAuthenticationCallback, CognitoUserSession, ICognitoUserPoolData, CognitoIdToken, CognitoRefreshToken, CognitoAccessToken } from 'amazon-cognito-identity-js';

import { SignUpModel, SignInModel, AuthOptions, CurrentUserOpts, ConfirmSignUpOptions, CognitoResponeErrorModel, ForgotPasswordSubmitModel, Auth0OAuthOpts } from './models';
import { GsCognitoUser, GsCognitoUserPool, OAuth, OAuthDialogService } from './core';
import { UtilityHelper } from '@framework/utilities';
import { environment } from '@app/env/environment';
import { LocalStorage } from '@framework/utilities/storage';

const USER_ADMIN_SCOPE = 'aws.cognito.signin.user.admin';

@Injectable()
export class CognitoAuthService {
    private authEnv = environment.aws;
    private userPool: GsCognitoUserPool;
    private config: AuthOptions;
    private isRememberMe: boolean = false;
    private oAuthHandler: OAuth;
    private readonly rememberMeStorageKey: string = 'RememberMeStorageKey';

    constructor(private utility: UtilityHelper, private oAuthDialogService: OAuthDialogService) {
        this.configure();
    }

    public configure() {
        // Create user pool
        this.createUserPool();

        const cognitoAuthParams: Auth0OAuthOpts = {
            domain: this.authEnv.oAuth.domain,
            clientID: this.authEnv.cognito.clientId,
            redirectUri: this.authEnv.oAuth.redirectUrl,
            responseType: 'code'
        };
        this.config = {
            authenticationFlowType: 'USER_SRP_AUTH',
            oauth: cognitoAuthParams
        };

        const scopes = ['email'];
        this.oAuthHandler = new OAuth(this.oAuthDialogService, cognitoAuthParams, scopes);
    }

    public signUp(signUp: SignUpModel) {
        if (!this.userPool) {
            return new Promise((resolve, reject) => this.errorHandler('No user pool', reject));
        };

        // Attributes
        let attrs: CognitoUserAttribute[] = signUp.attributes;

        // Validation data
        let validations: CognitoUserAttribute[] = [];

        return new Promise((resolve, reject) => {
            this.userPool.signUp(signUp.username, signUp.password, attrs, validations, (err, data) => {
                if (err) {
                    // SignUp failure
                    this.errorHandler(err, reject);
                } else {
                    // SignUp success
                    resolve(data);
                }
            });
        });
    }

    public confirmSignUp(username: string, code: string, option?: ConfirmSignUpOptions) {
        return new Promise((resolve, reject) => {
            if (!this.userPool) {
                this.errorHandler('No user pool', reject)
            }
            else {
                const user = this.createCognitoUser(username);
                const forceAliasCreation = option && option.forceAliasCreation || false;

                user.confirmRegistration(code, forceAliasCreation, (err, data) => {
                    if (err) {
                        // Confirm failure
                        this.errorHandler(err, reject)
                    } else {
                        // Confirm success
                        resolve(data);
                    }
                })
            }
        });
    }

    public resendSignUp(username: string) {
        return new Promise((resolve, reject) => {
            if (!this.userPool) {
                this.errorHandler('No user pool', reject);
            }
            else {
                const user = this.createCognitoUser(username);

                user.resendConfirmationCode((err, data) => {
                    if (err) {
                        // Resend failure
                        this.errorHandler(err, reject)
                    } else {
                        // Resend success
                        resolve(data);
                    }
                });
            }
        });
    }

    public signIn(signInData: SignInModel): Promise<GsCognitoUser> {
        if (!this.userPool) {
            return new Promise((resolve, reject) => this.errorHandler('No user pool', reject));
        };

        // Validation data
        let validationData = {};

        this.isRememberMe = signInData.rememberMe;
        const lStorage = this.utility.Storage.toInstance(LocalStorage);
        lStorage.setItem(this.rememberMeStorageKey, this.isRememberMe.toString());

        const authDetails = new AuthenticationDetails({
            Username: signInData.username,
            Password: signInData.password,
            ValidationData: validationData
        });
        if (signInData.password) {
            return this.signInWithPassword(authDetails);
        } else {
            return this.signInWithoutPassword(authDetails);
        }
    }

    public signInWithPassword(authDetails: AuthenticationDetails): Promise<GsCognitoUser> {
        const user = this.createCognitoUser(authDetails.getUsername());

        return new Promise((resolve, reject) => {
            user.authenticateUser(authDetails, this.authCallbacks(user, resolve, reject));
        });
    }

    public signInWithoutPassword(authDetails: AuthenticationDetails) {
        // Todo: Support in future
        return new Promise<GsCognitoUser>((resolve, reject) => this.errorHandler('Do not implement', reject));
    }

    public confirmSignIn() {
        // Todo: Support in future
    }

    public changePassword(oldPassword, newPassword) {
        const currentUser = this.currentUser();
        return new Promise((resolve, reject) => {
            this.userSession(currentUser).then((session) => {
                currentUser.changePassword(oldPassword, newPassword, (err, data) => {
                    if (err) {
                        return this.errorHandler(err, reject);
                    }
                    else {
                        return resolve(data);
                    }
                });
            }, (error) => {
                this.errorHandler(error, reject)
            });
        });
    }

    public forgotPassword(username: string) {
        var user = this.createCognitoUser(username);
        return new Promise((resolve, reject) => {
            user.forgotPassword({
                onSuccess: () => {
                    resolve();
                    return;
                },
                onFailure: (err) => {
                    this.errorHandler(err, reject);
                    return;
                },
                inputVerificationCode: (data) => {
                    resolve(data);
                    return;
                }
            });
        });
    }

    public forgotPasswordSubmit(data: ForgotPasswordSubmitModel) {
        const user = this.createCognitoUser(data.username);
        return new Promise((resolve, reject) => {
            user.confirmPassword(data.code, data.newPassword, {
                onSuccess: () => {
                    resolve();
                    return;
                },
                onFailure: (err) => {
                    this.errorHandler(err, reject);
                    return;
                }
            });
        });
    }

    public singOut() {
        const currentUser = this.currentUser();
        if (currentUser)
            currentUser.signOut();
    }

    public globalSignOut() {
        return new Promise(async (resolve, reject) => {
            const currentUserPoolUser = await this.currentUserPoolUser();
            currentUserPoolUser.globalSignOut({
                onSuccess: (data) => {
                    return resolve(data);
                },
                onFailure: (err) => {
                    return this.errorHandler(err, reject);
                }
            });
        });
    }

    public currentUser() {
        if (!this.userPool) return null;
        return this.userPool.getCurrentUser();
    }

    public getIdToken(): Promise<string> {
        return new Promise(async (resolve, reject) => {
            try {
                const cognitoUser = await this.currentUserPoolUser();
                if (!cognitoUser) {
                    resolve('');
                } else {
                    cognitoUser.getSession((error, session: CognitoUserSession) => {
                        if (!error) {
                            resolve(session.getIdToken().getJwtToken());
                        } else {
                            resolve('');
                        }
                    });
                }
            } catch (error) {
                resolve('');
            }
        });
    }

    public getAccessToken(): Promise<string> {
        return new Promise(async (resolve, reject) => {
            try {
                const cognitoUser = await this.currentUserPoolUser();
                if (!cognitoUser) {
                    resolve('');
                } else {
                    cognitoUser.getSession((error, session: CognitoUserSession) => {
                        if (!error) {
                            resolve(session.getAccessToken().getJwtToken());
                        } else {
                            resolve('');
                        }
                    });
                }
            } catch (error) {
                resolve('');
            }
        });
    }

    /**
    * Get the corresponding user session
    * @param {Object} user - The CognitoUser object
    * @return - A promise resolves to the session
    */
    public userSession(user): Promise<CognitoUserSession> {
        if (!user) {
            return new Promise((resolve, reject) => this.errorHandler('Failed to get the session because the user is empty', reject));
        }
        return new Promise((resolve, reject) => {
            user.getSession((err, session) => {
                if (err) {
                    this.errorHandler(err, reject);
                    return;
                } else {
                    resolve(session);
                    return;
                }
            });
        });
    }

    /**
     * Get current authenticated user
     * @return - A promise resolves to current authenticated CognitoUser if success
     */
    public currentUserPoolUser(params?: CurrentUserOpts): Promise<GsCognitoUser | any> {
        if (!this.userPool) {
            return new Promise((resolve, reject) => this.errorHandler('No userPool', reject));
        }
        return new Promise((resolve, reject) => {
            const user = this.currentUser();
            if (!user) {
                this.errorHandler('No current user', reject);
                return;
            }

            // refresh the session if the session expired.
            user.getSession((err, session) => {
                if (err) {
                    this.errorHandler(err, reject);
                    return;
                }

                // get user data from Cognito
                const bypassCache = params ? params.bypassCache : false;
                // validate the token's scope fisrt before calling this function
                const { scope = '' } = session.getAccessToken().decodePayload();
                if (scope.split(' ').includes(USER_ADMIN_SCOPE)) {
                    user.getGsUserData(
                        (err, data) => {
                            if (err) {
                                // Make sure the user is still valid
                                if (err.message === 'User is disabled' || err.message === 'User does not exist.') {
                                    this.errorHandler(err, reject)
                                } else {
                                    // the error may also be thrown when lack of permissions to get user info etc
                                    // in that case we just bypass the error
                                    resolve(user);
                                }
                                return;
                            }
                            const preferredMFA = data.PreferredMfaSetting || 'NOMFA';
                            const attributeList = [];

                            for (let i = 0; i < data.UserAttributes.length; i++) {
                                const attribute = {
                                    Name: data.UserAttributes[i].Name,
                                    Value: data.UserAttributes[i].Value,
                                };
                                const userAttribute = new CognitoUserAttribute(attribute);
                                attributeList.push(userAttribute);
                            }

                            const attributes = this.attributesToObject(attributeList);
                            Object.assign(user, { attributes, preferredMFA });
                            return resolve(user);
                        },
                        { bypassCache }
                    );
                } else {
                    console.debug(`Unable to get the user data because the ${USER_ADMIN_SCOPE} ` +
                        `is not in the scopes of the access token`);
                    return resolve(user);
                }
            });
        });
    }

    public async federatedSignIn(signInData: SignInModel) {
        this.isRememberMe = signInData.rememberMe;
        const lStorage = this.utility.Storage.toInstance(LocalStorage);
        lStorage.setItem(this.rememberMeStorageKey, this.isRememberMe.toString());

        const { accessToken, refreshToken, idToken } = await this.oAuthHandler.oauthSignIn('code', signInData.provider);
        const session = new CognitoUserSession({
            IdToken: new CognitoIdToken({ IdToken: idToken }),
            RefreshToken: new CognitoRefreshToken({ RefreshToken: refreshToken }),
            AccessToken: new CognitoAccessToken({ AccessToken: accessToken })
        });
        const userName = session.getIdToken().decodePayload()['cognito:username'];
        const user = this.createCognitoUser(userName);
        user.setSignInUserSession(session);
    }

    // Private functions
    private authCallbacks(user: GsCognitoUser, resolve, reject): IAuthenticationCallback {
        const that = this;
        return {
            onSuccess: async (session) => {
                delete (user['challengeName']);
                delete (user['challengeParam']);
                try {
                    // In order to get user attributes and MFA methods
                    // We need to trigger currentUserPoolUser again
                    const currentUser = await this.currentUserPoolUser();
                    that['user'] = currentUser;
                    resolve(currentUser);
                } catch (err) {
                    this.errorHandler(err, reject)
                }
            },
            onFailure: (err) => {
                this.errorHandler(err, reject)
            },
            customChallenge: (challengeParam) => {
                user['challengeName'] = 'CUSTOM_CHALLENGE';
                user['challengeParam'] = challengeParam;
                resolve(user);
            },
            mfaRequired: (challengeName, challengeParam) => {
                user['challengeName'] = challengeName;
                user['challengeParam'] = challengeParam;
                resolve(user);
            },
            mfaSetup: (challengeName, challengeParam) => {
                user['challengeName'] = challengeName;
                user['challengeParam'] = challengeParam;
                resolve(user);
            },
            newPasswordRequired: (userAttributes, requiredAttributes) => {
                user['challengeName'] = 'NEW_PASSWORD_REQUIRED';
                user['challengeParam'] = {
                    userAttributes,
                    requiredAttributes
                };
                resolve(user);
            },
            totpRequired: (challengeName, challengeParam) => {
                user['challengeName'] = challengeName;
                user['challengeParam'] = challengeParam;
                resolve(user);
            },
            selectMFAType: (challengeName, challengeParam) => {
                user['challengeName'] = challengeName;
                user['challengeParam'] = challengeParam;
                resolve(user);
            }
        };
    }

    private attributesToObject(attributes) {
        const obj = {};
        if (attributes) {
            attributes.map(attribute => {
                if (attribute.Value === 'true') {
                    obj[attribute.Name] = true;
                } else if (attribute.Value === 'false') {
                    obj[attribute.Name] = false;
                } else {
                    obj[attribute.Name] = attribute.Value;
                }
            });
        }
        return obj;
    }

    private createUserPool() {
        const poolData: ICognitoUserPoolData = {
            UserPoolId: this.authEnv.cognito.userPoolId,
            ClientId: this.authEnv.cognito.clientId,
            Storage: sessionStorage
        };

        const lStorage = this.utility.Storage.toInstance(LocalStorage);
        const isRememberMe = lStorage.getItemValue(this.rememberMeStorageKey) == 'true' ? true : false;
        if (isRememberMe === true) {
            poolData.Storage = localStorage;
        }

        this.userPool = new GsCognitoUserPool(poolData);
    }

    private createCognitoUser(username: string) {
        const userData: ICognitoUserData = {
            Username: username,
            Pool: this.userPool,
            Storage: sessionStorage
        };

        const lStorage = this.utility.Storage.toInstance(LocalStorage);
        const isRememberMe = lStorage.getItemValue(this.rememberMeStorageKey) == 'true' ? true : false;
        if (isRememberMe === true) {
            userData.Storage = localStorage;
            this.userPool.changeStorage(localStorage);
        }

        const authenticationFlowType = this.config.authenticationFlowType;

        const cognitoUser = new GsCognitoUser(userData);
        if (authenticationFlowType) {
            cognitoUser.setAuthenticationFlowType(authenticationFlowType);
        }
        return cognitoUser;
    }

    private errorHandler(error: any, reject) {
        const resError: CognitoResponeErrorModel = {
            message: error.message || error,
            code: error.code || 'GeneralError'
        };
        reject(resError);
    }
}