import { ModelBase } from '../base/base.model';
import { GsCognitoUser } from '@framework/cloud/cognito/core';

export class AuthorizedUserModel extends ModelBase {
    public gsUser: GsCognitoUser


    constructor(init?: Partial<AuthorizedUserModel>) {
        super(init);
        Object.assign(this, init);
    }
}