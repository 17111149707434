export interface ForgotPasswordSubmitModel {
    username: string,
    code: string,
    newPassword: string
}

export class ForgotPasswordSubmitModel {
    constructor() {

    }
}