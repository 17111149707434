import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CoreModule } from '@app/modules/core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

const routes: Routes = [];

@NgModule({
  imports: [
    BrowserAnimationsModule,
    RouterModule.forRoot(routes),
    CoreModule.forRoot(),
  ],
  exports: [RouterModule, CoreModule, BrowserAnimationsModule]
})
export class AppRoutingModule { }
