import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutHeaderComponent } from './layout-header/layout-header.component';
import { LayoutSidebarComponent } from './layout-sidebar/layout-sidebar.component';
import { RouterModule } from '@angular/router';
import { BreadcrumComponent } from './breadcrum/breadcrum.component';


@NgModule({
  declarations: [LayoutHeaderComponent, LayoutSidebarComponent, BreadcrumComponent],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [LayoutHeaderComponent, LayoutSidebarComponent, BreadcrumComponent]
})
export class ComponentModule { }
