export interface SignInModel {
    username?: string,
    password?: string,
    rememberMe: boolean,
    provider?: string
}

export class SignInModel {
    constructor() {

    }
}