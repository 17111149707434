import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { appSettingActions } from '../actions';
import { AppSettingHttpService } from '@app/modules/core/services/app-setting.http-service';
import * as inputApi from '@app/modules/shared/models/api-models/input';
import * as outputApi from '@app/modules/shared/models/api-models/output';

@Injectable()
export class AppSettingEffects {
    constructor(private actions$: Actions,
        private appSettingHttpService: AppSettingHttpService) {

    }

    getAppSetting$ = createEffect(() =>
        this.actions$.pipe(
            ofType(appSettingActions.getAppSetting),
            switchMap(({ input }) => {
                return this.appSettingHttpService.getAppSetting(input).pipe(
                    map((output: outputApi.GetAppSettingOutputApiModel) => {
                        return appSettingActions.getAppSettingSuccess({ output });
                    }),
                    catchError((error: any) => {
                        return of(appSettingActions.getAppSettingFail({ input, error }));
                    })
                );                
            })
        )
    );
}