import { GetUserProfileOutputApiModel } from './../../../modules/shared/models/api-models/output/get-user-profile-output.api-model';

export interface AuthState {
    userContext: any,
    redirectUrl: string,
    isAuthenticated: boolean,
    userProfile: GetUserProfileOutputApiModel
}

export const initialAuthState: AuthState = {
    userContext: null,
    redirectUrl: '',
    isAuthenticated: false,
    userProfile: null
}
